<template>
  <div class="container pt-4 text-center">
    <div class="row d-flex align-items-center justify-content-center">
   
        <h2>   تستطيع التواصل معنا عن طريق الواتساب</h2>







    

     
       
                <button
   type="button"
   class=" mr-2 btn btn-success btn-lg font-weight-bold"
   style="direction:ltr!important; "
   @click="openWhats" 
 >
   <i class="whatsapp icon"></i> اضغط هنا
 </button>
       
       
       
       
  
  
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWhats() {
      window.open("https://api.whatsapp.com/send?phone=966562240373", "_blank");
    },
  },
};
</script>
